import React from "react";
import "./_control.scss";
const Control = () => {
  return (
    <div className="control">
      <div className="max__width">
        <div className="image">
          <img src="/assets/images/typingKeyboardForDarkBg.svg" alt="" />
        </div>
        <div className="control-desc">
          <h2>Your Password, Your Control</h2>

          <div className="desc">
            <p>
              PassGeni enables you to generate passwords that are both easy to
              remember and secure. Our user-friendly free AI password generator
              tool combines words with a special character, and you can capitalize words as you prefer. Plus, you can
              easily add a random 4-digit string to meet specific password
              requirements.
            </p>
            <p>
              The best part? All automated password generation occurs right in
              your browser, with no data sent to our servers. With PassGeni AI
              Password Generator, you completely control your online security.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Control;
