import React from "react";
import "./_content.scss";
const Content = () => {
  return (
    <div className="content">
      <div className="max__width">
        <div className="width">
          <h2>
            Advanced AI-Enhanced Password Security
          </h2>
          <p>
            Experience the PassGeni difference with these exclusive features:
          </p>
          <div className="center-div">
            <p className="custom_para">
              <span>AI-Powered Strength : </span>
              PassGeni utilises the power of artificial intelligence to craft the Best, Powerful, and Memorable passwords, making them unbreakable
            </p>
            <p className="custom_para">
              <span>Professionally Personalized : </span>
              Choose profession, and PassGeni will generate memorable passphrases relevant to your field, ensuring security and ease of use.
            </p>
            <p className="custom_para">
              <span>Zero Data Retention : </span>
              PassGeni never stores your passwords, guaranteeing the utmost security for your sensitive information.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
