import React, { useState } from "react";
import "./_faq.scss";
const faq = [
  {
    id: 1,
    title: "Is Passgeni safe to use?",
    desc: (
      <p>
        <span className="weight"> Absolutely! </span> All passwords you create
        with the
        <span className="weight"> Passgeni </span>
        are generated by <span className="weight"> OpenAI </span>in your browser
        on your computer. They are <span className="weight"> not saved </span>
        or <span className="weight"> shared </span> anywhere, even Passgeni
        <span className="weight"> can't </span> see your password. Just be sure
        to
        <span className="weight"> close this page </span> once you’ve
        <span className="weight"> generated </span> and
        <span className="weight">copied</span>
        your password to prevent anyone else from seeing it if they use your
        computer.
      </p>
    ),
  },
  {
    id: 2,
    title: "Do I need a unique password for every account?",
    desc: (
      <p>
        <span className="weight"> 100%, </span>Using the same password across
        multiple accounts is a<span className="weight"> huge no-no. </span>
        If hackers learn the <span className="weight"> password </span>to one of
        your accounts, <span className="weight"> not saved </span>they’ll have
        your
        <span className="weight"> password </span>for all the others,
        <span className="weight"> too. </span> So make sure you use a
        <span className="weight"> unique password </span> for each account.
      </p>
    ),
  },
  {
    id: 3,
    title:
      "What is the difference between a Random password and memorable passwords?",
    desc: (
      <p>
        Memorable passwords or
        <span className="weight">'passphrases'</span>, are typically a
        combination
        <span className="weight"> of 3-6 words strung together </span> into a
        <span className="weight"> mnemonic device </span> . Because passphrases
        are often
        <span className="weight"> over 20 characters or longer </span>, they are
        <span className="weight"> extremely </span> resilient to
        <span className="weight"> brute force attacks, </span> but also easy to
        recall compared to a<span className="weight"> random </span> string of
        20 characters.
        <span className="weight"> </span>
        <span className="weight"></span>
      </p>
    ),
  },
  {
    id: 4,
    title: "How does AI assist in creating unique and complex passwords?",
    desc: (
      <p>
        <span className="weight">AI </span> utilizes advanced algorithms to
        <span className="weight"> generate passwords </span>
        that are both <span className="weight"> unique </span>and
        <span className="weight"> complex </span> , making it extremely
        <span className="weight"> challenging </span> for
        <span className="weight"> cybercriminals </span> to
        <span className="weight"> crack </span> them.
      </p>
    ),
  },
  {
    id: 5,
    title: "Are AI-generated passwords easier to remember?",
    desc: (
      <p>
        <span className="weight"> Yes, </span> AI takes into account
        <span className="weight">memorability</span>, crafting passphrases or
        <span className="weight"> passwords </span> that are
        <span className="weight"> easier </span> to recall while still
        <span className="weight"> meeting security </span> standards.
      </p>
    ),
  },
  {
    id: 6,
    title: "How can AI benefit businesses in terms of password security?",
    desc: (
      <p>
        <span className="weight"> AI-powered </span> password solutions enhance
        <span className="weight"> security</span> by generating strong
        passwords, managing them
        <span className="weight">efficiently </span> , and regularly assessing
        their
        <span className="weight"> strength </span> , all for free. This reduces
        the
        <span className="weight"> risk of data breaches </span> and ensures that
        <span className="weight"> employees </span> maintain robust
        <span className="weight"> password </span> practices.
      </p>
    ),
  },
  {
    id: 7,
    title: "Are AI-driven password management tools user-friendly?",
    desc: (
      <p>
        Yes, these
        <span className="weight">tools </span> are designed with
        <span className="weight"> user-friendliness </span>
        in mind. They simplify{" "}
        <span className="weight"> password management </span> , often providing
        features like
        <span className="weight"> automatic password </span> generation and
        one-click login for ease of use.
      </p>
    ),
  },
];
const Faq = () => {
  const [active, setActive] = useState(1);
  const handleItemClick = (faqId) => {
    if (faqId === active) {
      setActive(null);
    } else {
      setActive(faqId);
    }
  };
  return (
    <div className="faq" id="faqs">
      <div className="max__width">
        <h2> FAQs</h2>
        <p className="sub">
          Questions about this random password generator? Answers below!
        </p>
        <div className="faq-card">
          {faq.map((faq) => (
            <div
              className="card"
              key={faq.id}
              onClick={() => handleItemClick(faq.id)}
            >
              <div className="flex">
                <p className="title">{faq.title}</p>
                <div className={`${faq.id === active && "rotate"} `}>
                  <img src="/assets/images/faq.svg" alt="icon" />
                </div>
              </div>

              {faq.id === active ? (
                <div
                  className={`detail ${faq.id === active ? "h-full" : "h-null"
                    } `}
                >
                  <p>{faq.desc}</p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
