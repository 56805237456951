import React, { useState } from "react";
import "./_nav.scss";
import { Link } from "react-router-dom";
import { HiMiniXMark, HiMiniBars3CenterLeft } from "react-icons/hi2";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };
  const links = [
    {
      name: "Generator",
      path: "#generator",
    },
    {
      name: "About",
      path: "#about",
    },
    {
      name: "FAQS",
      path: "#faqs",
    },
  ];
  return (
    <div className="navbar">
      <div className="max__width">
        <Link to="/" className="logo">
          <img
            className="innerImage"
            src="/assets/images/headerColorLogo.svg"
            alt="passgeni-logo"
          />
          <p className="beta">beta</p>
        </Link>
        <button onClick={handleOpen} className="bar">
          {isOpen ? (
            <HiMiniXMark className="close" />
          ) : (
            <HiMiniBars3CenterLeft className="open" />
          )}
        </button>
        <ul className={"links"}>
          {links.map((link, i) => (
            <a href={link.path} className="link" key={i}>
              {link.name}
            </a>
          ))}

        </ul>
      </div>
      {isOpen && (
        <ul className={"nav-links"}>
          <div className="center ">
            {links.map((link, i) => (
              <a
                href={link.path}
                className="link"
                key={i}
                onClick={() => setIsOpen(false)}
              >
                {link.name}
              </a>
            ))}
            <Link to="/blog" onClick={() => setIsOpen(false)}>
              Blog
            </Link>
          </div>

          <div className="term">
            <div className="a-flex">
              <a href="#terms" alt="terms">
                Terms
              </a>
              |
              <a href="#privacy" alt="privacy">
                Privacy
              </a>
            </div>
          </div>
        </ul>
      )}
    </div>
  );
};

export default Navbar;
