import { React, useRef, useState } from "react";
import "./_generate.scss";
import { Modal } from "../Model/Model";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { getPass } from "../../api/apiService";
import { BsCheckCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

const Generate = () => {
  const [showModal, setShowModal] = useState(false);
  const [rangeValue, setRangeValue] = useState(3);
  const [pass, setPass] = useState("tape-goes-product-report");
  const [strength, setStrength] = useState("Very Strong");
  const [copied, setCopied] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [profession, setProfession] = useState("");
  const [seperator, setSeperator] = useState("-");

  const reRef = useRef();

  const handleChange = (newValue) => {
    setRangeValue(newValue);
  };

  const checkPasswordStrength = (password) => {
    const lengthRegex = /^.{8,}$/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;

    let strength = 0;

    if (lengthRegex.test(password)) strength++;
    if (lowercaseRegex.test(password)) strength++;
    if (uppercaseRegex.test(password)) strength++;
    if (digitRegex.test(password)) strength++;
    if (specialCharRegex.test(password)) strength++;

    if (strength === 0) {
      return "Very Weak";
    } else if (strength === 1) {
      return "Weak";
    } else if (strength === 2) {
      return "Moderate";
    } else if (strength === 3) {
      return "Strong";
    } else if (strength === 4) {
      return "Very Strong";
    } else {
      return "Exceptional";
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(pass);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 600);
  };

  const isNumberAdded = () => {
    const numCheckbox = document.getElementById("addNumber");
    return numCheckbox.checked;
  };

  const checkCapitalize = (input) => {
    let capCheckbox = document.getElementById("capitalize");
    if (capCheckbox.checked) {
      return capitalize(input);
    } else {
      return decapitalize(input);
    }
  };

  const generatePassword = async () => {
    if (!fetching) {
      setFetching(true);
      try {
        const token = await reRef.current.executeAsync();
        reRef.current.reset();
        let passwordResponse = await getPass(profession, rangeValue, token);
        let passwordData = passwordResponse.data;
        let myPass = passwordData.password.toLowerCase();
        if (seperator !== "-") {
          let passArr = myPass.split("-");
          myPass = passArr.join(seperator);
        }
        if (isNumberAdded()) {
          let str = `${myPass}${seperator}${Math.floor(
            Math.random() * 9
          )}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}`;
          myPass = checkCapitalize(str);
        } else {
          myPass = checkCapitalize(myPass);
        }
        setPass(myPass);
      } catch (e) {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong!",
          icon: "error",
          confirmButtonText: "Try Again",
          confirmButtonColor: "#027373",
        });
      } finally {
        setFetching(false);
        setStrength(checkPasswordStrength(pass));
      }
    }
  };

  const handleAddNumber = (e) => {
    if (e.target.checked) {
      setPass(
        `${pass}${seperator}${Math.floor(Math.random() * 9)}${Math.floor(
          Math.random() * 9
        )}${Math.floor(Math.random() * 9)}`
      );
    } else {
      setPass(`${pass.slice(0, -4)}`);
    }
    setStrength(checkPasswordStrength(pass));
  };

  const handleCapitalize = (e) => {
    if (e.target.checked) {
      setPass(capitalize(pass));
    } else {
      setPass(decapitalize(pass));
    }
    setStrength(checkPasswordStrength(pass));
  };

  const capitalize = (input) => {
    const words = input.split(seperator);
    const result = [];

    for (let word of words) {
      if (isNaN(word)) {
        result.push(word.charAt(0).toUpperCase() + word.slice(1));
      } else {
        result.push(word);
      }
    }

    return (
      result.join(seperator).charAt(0).toUpperCase() +
      result.join(seperator).slice(1)
    );
  };

  const decapitalize = (input) => {
    const words = input.split(seperator);
    const result = [];

    for (let word of words) {
      if (isNaN(word)) {
        result.push(word.charAt(0).toLowerCase() + word.slice(1));
      } else {
        result.push(word);
      }
    }

    return (
      result.join(seperator).charAt(0).toLowerCase() +
      result.join(seperator).slice(1)
    );
  };

  const handleSeperatorChange = (e) => {
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    if (e.target.value === "") {
      changeSeperator("-");
    } else if (regex.test(e.target.value)) {
      changeSeperator(e.target.value);
    } else {
      e.target.value = "";
    }
  };

  const changeSeperator = (sep) => {
    let arr = pass.split(seperator);
    let str = arr.join(sep);
    setSeperator(sep);
    setPass(str);
  };

  return (
    <div className="generate" id="generator">
      <div className="max__width generate__container">
        <h2>
          Generate Smart Passwords <br /> with{" "}
          <span className="passgeni"> PassGeni </span>
          Free AI Password Generator Tool
        </h2>
        <div className="detail">
          <div className="generate-field">
            <div className="generate-field__input">
              <h4>{fetching ? "Loading . . ." : pass}</h4>
              <button className="hard">{strength}</button>
            </div>
            <div className="flex">
              <button className="ai-generate point" onClick={generatePassword}>
                {fetching ? ". . ." : "AI Generator"}
              </button>
              <button className="copy point" onClick={handleCopyClick}>
                {copied ? <BsCheckCircleFill color="white" /> : "Copy"}
              </button>
            </div>
          </div>
          <div className="line">
            {/* silder */}
            <div className="slider">
              <Slider
                min={2}
                max={4}
                value={rangeValue}
                onChange={handleChange}
              />
            </div>

            {/* --------- */}
          </div>
          <div className="genarate-input">
            <input
              onChange={(e) => setProfession(e.target.value.trim())}
              type="text"
              placeholder="Your Profession"
            />
            <input
              onChange={handleSeperatorChange}
              type="text"
              placeholder="Your Word-Separator (!@#$%^&*+><?/) "
              maxLength={1}
            />
          </div>
          <div className="add-input">
            <div className="add-input__flex">
              <input
                type="checkbox"
                name="addNumber"
                id="addNumber"
                onClick={handleAddNumber}
              />
              <label htmlFor="addNumber">
                <p>Add Number</p>
              </label>
            </div>
            <div className="add-input__flex">
              <input
                type="checkbox"
                name="capitalize"
                id="capitalize"
                onClick={handleCapitalize}
              />
              <label htmlFor="capitalize">
                <p>Add Capitalize</p>
              </label>
            </div>
          </div>
          <div className="desc">
            <h2>I have some feedback / enquiry</h2>
            <p>
              We value your feedback! Please share your thoughts, suggestions,
              or concerns with us. Your input helps us improve our services.
              Thank you for helping us grow our password generator tool.
            </p>
            <button onClick={() => setShowModal(true)} className="feedback_btn">
              Feedback
            </button>
            {showModal && <Modal setShowModal={setShowModal} />}
          </div>
        </div>
      </div>
      <ReCAPTCHA
        sitekey="6LcZymwoAAAAAHKQveEGu59EIlZcZnw0Jfwox6Nt"
        size="invisible"
        ref={reRef}
      />
    </div>
  );
};

export default Generate;
