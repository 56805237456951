import React from "react";
import "./_features.scss";
const Features = () => {
  return (
    <div className="feat" id="about">
      <div className="max__width">
        <h2>
          Advance Business Security with <br /> AI-Powered Password Solutions
        </h2>
        <div className="p-col">
          <p>
            Statistics reveal that 43% of data breaches target small businesses, with the average data breach cost reaching a staggering $3.86 million. Weak and reused passwords can lead to unauthorized access to your company's data. This is where AI-powered password managers come into play.

            AI-powered password Generators like PassGeni create strong and secure passphrases. When you need to log into a site, PassGeni will autofill the login details.

            Furthermore, PassGeni's AI password strength checker continually assesses and enhances password security. Businesses can safeguard sensitive data by integrating AI-enhanced password protection into their cybersecurity strategy.

          </p>
          <p>
            It’s also quite hard to remember all those passwords when you need
            them. That’s why we made PassGeni. PassGeni is a password manager
            app that works on almost any device to generate secure passwords on
            the fly. When you need to log into a site, PassGeni will autofill
            the login details for you. All you need to remember now is one
            secure password – that unlocks all of the random, strong passwords
            the built-in password generator created for you.
          </p>
        </div>
        <div className="hr" />
        <div className="f-col">
          <h2>
            I need to generate a memorable, but <br /> strong password. Any
            tips?
          </h2>
          <p>
            A random password generator is the best way to generate passwords
            that are both secure and easy to remember. But if you find yourself
            without access to the Strong Password Generator tool, keep these
            tips in mind to stay safe online.
          </p>
        </div>
        <div className="cards">
          <div className="card">
            <p className="p">
              Create a passphrase using random words or a memorable sentence. <span> Statistically, passphrases are more secure and easier to remember than traditional passwords.</span>  Passphrases like "Sunny/Banana/Blue/Skies$" are easier to remember & more secure due to their length & complexity.
            </p>
            <div className="absolute">
              <p>
                Passphrase Power
                <br />
              </p>
              <div className="red">
                <div className="img">
                  <img src="/assets/images/check.svg" alt="icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <p className="p">

              Avoid quickly guessable information like birthdays or common words. <span> Incorporate profession or hobbies into your passwords. </span> Personalized elements, such as your favorite book title or hobby, can make your password easier to recall without sacrificing security.
            </p>
            <div className="absolute">
              <p>
                Personalize with Purpose
                <br />
              </p>
              <div className="img">
                <img src="/assets/images/check.svg" alt="icon" />
              </div>
            </div>
          </div>
          <div className="card">
            <p className="p">

              Steer clear of obvious patterns like <span> "123456" or "password. </span>" These are among the most commonly used passwords, making them a target for hackers. They are well aware of common password patterns, so breaking away from predictability is essential.

            </p>
            <div className="absolute">
              <p>
                Avoid Predictable Patterns
              </p>
              <div className="img-red">
                <img src="/assets/images/cancle.svg" alt="icon" />
              </div>
            </div>
          </div>
          <div className="card">
            <p className="p">
              Use a reliable password generator to securely generate and store complex passwords. This technology ensures you don't have to remember every password. Password generators like PassGeni can create and remember strong, unique passwords quickly.

            </p>
            <div className="absolute">
              <p>Use a Password Manager</p>
              <div className="img">
                <img src="/assets/images/check.svg" alt="icon" />
              </div>
            </div>
          </div>
          <div className="card">
            <p className="p">
              Specific numbers, uppercase & lowercase letters, & symbols don't make strong passwords. Surprisingly, 52% of people still use guessable patterns like "PaSsw0rd123." Our random password generator includes options to add symbols & capital letters to accommodate your requirements.
            </p>
            <div className="absolute">
              <p>
                Decode Password Complexity
              </p>
              <div classsName="green">
                <div className="img">
                  <img src="/assets/images/check.svg" alt="icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
