import { Route, Routes } from "react-router-dom";
import {
  Features,
  Content,
  Control,
  Generate,
  Home,
  Navbar,
  Testimonial,
  Faq,
  Footer,
  Trusted,
} from "./custom";

function App() {
  return (
    <div className="">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />
              <Generate />
              <Content />
              <Control />
              <Trusted />
              <Testimonial />
              <Features />
              <Faq />
              <Footer />
            </>
          }
        />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </div>
  );
}

export default App;
