import React from "react";

import "./_test.scss";
// import { FaStar } from "react-icons/fa";

const Data = [
  {
    id: 1,
    para: "Did you know that 81% of data breaches occur due to weak or reused passwords? Passphrases, like those generated with PassGeni, significantly boost security. They consist of multiple words or a sentence, making them 30 times harder to crack than traditional passwords. You can stay one step ahead of cyber threats"
  },
  {
    id: 2,
    para: "I’ve used Passgeni for a few weeks and it has proven time and time again how valuable it is to me.Managing my passwords across my devices is so easy with my subscription"
  },
  {
    id: 3,
    para: "Are you among the 60% of people who admit to using the same password across multiple accounts?    Managing multiple complex passwords is exhausting. An Artificial Intelligence (AI) password manager provides a solution by crafting strong yet memorable passphrases according to your preferences. Reduce the risk of forgetting or reusing passwords across accounts."
  },
  {
    id: 4,
    para: "Did you know that every 3 in 4 accounts get compromised due to weak passwords every year? AI-driven password generators help you create Smart Passwords Online by consistently generating robust and unique passphrases. Safeguard your online accounts effectively. Don't compromise your online security – stay informed and protected with PassGeni's AI-powered solutions."
  },
]

const Card = (props) => {
  return (
    <>

      {
        Data.map((val, ind) => {
          return (
            <div className="card">
              <div className="star">
                {/* <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar /> */}
              </div>
              <p>
                {val.para}

              </p>
            </div>
          )
        })
      }
    </>
  );
};

const Testimonial = () => {
  return (
    <div className="testimonial">
      <div className="max__width">
        <h2>  Join <span className="special-text-test"> 100 Million+ </span> Users Using AI <br /> for Maximum Password Security - Here's How!"</h2>
        <div className="grid">
          <Card />

        </div>
      </div>
    </div>
  );
};

export default Testimonial;
