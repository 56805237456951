import axios from "axios";

const BASE_URL = 'https://passgeni.online';

export const getPass = (profession, numbers, token) => {
  const params = {
    profession: profession,
    numbers: numbers,
    token: token,
  };
  const data = JSON.stringify(params);
  return axios({
    method: 'post',
    url: `${BASE_URL}/api/passwords/getPassword`,
    data: data,
    mode:'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
  });
};

export const postUserFeedback = (data) => {
  return axios.post(`${BASE_URL}/api/feedback/user-feedback`, data);
};
