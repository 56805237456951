import React from "react";
import "./_footer.scss";
const Footer = () => {
  return (
    <div className="footer">
      <div className="max__width">
        <h2>
          <span>Save time. </span> <br /> Access from anywhere. <br /> Get more
          secure passwords.
        </h2>
        <div className="get">
          <div className="btn">
            <img
              src="./assets/images/google-play.svg"
              alt="google"
              className="img"
            />
            <div className="btn__col">
              <div class="badge pulsate">Coming Soon</div>
              <p>GET IT ON</p>
              <h3>Google Play</h3>
            </div>
          </div>
          <div className="btn">
            <img
              src="./assets/images/extension.svg"
              alt="chrome"
              className="img"
            />
            <div className="btn__col">
              <div class="badge pulsate">Coming Soon</div>
              <p>GET IT ON</p>
              <h3>Chrome Extension</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="under">
        <div className="max__width">
          <div className="logo ">
            <img
              src="./assets/images/headerWhiteLogo.svg"
              alt="passgeni"
              className="img"
            />
            <p className="beta">beta</p>
          </div>
          <p className="copy">
            ©2023 PassGeni. Built by
            <span className="underline"> Giant Tech Solutions, LLC</span>
          </p>
          <div className="hr" />
          <div className="a-flex">
            <a href="#terms" alt="terms">
              Terms
            </a>
            |
            <a href="#privacy" alt="privacy">
              Privacy
            </a>
          </div>
        </div>
      </div>
      <div className="footer__img">
        <div className="max__width img">
          <img
            src="./assets/images/footer-image.svg"
            alt="footer-bg"
            className="img"
          />
        </div>
      </div>
      <div className="footer__gradient" />
    </div>
  );
};

export default Footer;
