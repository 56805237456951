import React, { useState } from "react";
import { postUserFeedback } from "../../api/apiService";
import { HiX } from "react-icons/hi";
import "./style.scss";
import Swal from "sweetalert2";
// import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';

export const Modal = ({ setShowModal }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const data = { name, email, message };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      alert("Please enter your name");
    } else if (email === "") {
      alert("Please enter your email");
    } else if (message === "") {
      alert("Please enter your message");
    } else {
      postUserFeedback(data)
        .then((response) => {
          handleSweetAlert();
          setName("");
          setEmail("");
          setMessage("");
          setShowModal(false);
        })
        .catch((err) => {
          console.error("Error while posting feedback:", err);
        });
    }
  };
  const handleSweetAlert = () => {
    Swal.fire({
      title: "Thank You For Your Feedback!",
      text: "Your feedback has been reveived!",
      icon: "success",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  return (
    <>
      <div className="outerDiv" onClick={() => setShowModal(false)}></div>
      <div className="mainModal">
        <div className="crossIcon" onClick={() => setShowModal(false)}>
          <HiX />
        </div>
        <form onSubmit={handleSubmit} className="mainForm">
          <h2 className="h1">Feedback Form</h2>

          <div className="inputDiv">

            <input
              type="text"
              name="name"
              value={name}
              placeholder="Your Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="inputDiv">

            <input
              type="email"
              name="email"
              value={email}
              placeholder="Your Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="inputDiv">

            <textarea
              id=""
              cols="30"
              rows="5"
              name="textarea"
              placeholder="Your Feedback"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button type="submit" id="button">
            Submit
          </button>
        </form>

      </div>

    </>
  );
};
