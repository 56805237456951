import React from "react";
import "./_home.scss";
const Home = () => {
  const links = [
    {
      name: "Generate",
      path: "#generator",
    },
  ]
  return (
    <div className="home">
      <div className="max__width home__container">
        <div className="home-detail">
          <h1 className="welcome">
            Welcome to PassGeni - Your AI Password Generator
          </h1>
          <h2>
            Generate Strong and Secure Passwords with AI-Powered Password Generator
          </h2>
          {links.map((link, i) => (
            <a href={link.path} className="btn-generate" key={i}>
              {link.name}
            </a>
          ))}
        </div>
        <div className="home-img">
          <img
            src="/assets/images/small_screen_vector.svg"
            alt="svg"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="power">
        <div className="power__flex">
          <p className="flex">powered By:</p>
          <img src="/assets/images/openAI.svg" alt="openAI" />
        </div>
      </div>
    </div>
  );
};

export default Home;
