import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './theme';
import loaderReducer from './loader';

export default configureStore({
  reducer: {
    theme: themeReducer,
    loading: loaderReducer,
  },
});
