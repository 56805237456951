import React from "react";
import "./_trusted.scss";
const Trusted = () => {
  return (
    <div className="trust">
      <div className="max__width">
        <div className="campanies">
          <img
            src="/assets/images/giantIcon.svg"
            alt="giant-icon"
            className="img"
          />
          <img
            src="/assets/images/filnofyIcon.svg"
            alt="filnofy-icon"
            className="img"
          />
          <img
            src="/assets/images/namoonIcon.svg"
            alt="namooon-icon"
            className="img g"
          />
        </div>
      </div>
    </div>
  );
};

export default Trusted;
